import { Injectable } from '@angular/core';
import { Ad, AdCache, AdSearchParams, Category, User } from './interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private profile_cache: AdCache = {} as AdCache;
  private search_cache: AdCache = {} as AdCache;
  private ad_visible_count: number | undefined;
  private ad_total_count: number | undefined;
  private apiService: ApiService | undefined;

  constructor(private globalService: GlobalService) {}

  setApiService(apiService: ApiService) {
    this.apiService = apiService;
  }

  refreshCacheFromCurrentPage(removed: boolean = false) {
    if (this.globalService.getCurrentRoute()?.startsWith('/profile/')) {
      this.clearSearchCachedPages();
      let searchParam = localStorage.getItem('profile.filter');
      this.profile_cache.pages.forEach((p) => {
        if (p.page >= this.globalService.getProfileCurrentPage()) {
          this.profile_cache.pages.splice(
            this.profile_cache.pages.indexOf(p),
            1
          );
        }
      });
      if (removed && this.profile_cache.ad_count) {
        this.profile_cache.ad_count--;
        let newPageCount = Math.ceil(this.profile_cache.ad_count / 10);
        if (newPageCount < Number(this.globalService.getProfileCurrentPage())) {
          this.globalService.setProfileCurrentPage(newPageCount);
          localStorage.setItem(
            'profile.page',
            this.globalService.getProfileCurrentPage().toString()
          );
        }
      }

      this.apiService?.getAds(
        {
          page: this.globalService.getProfileCurrentPage() - 1,
          search: searchParam,
        } as AdSearchParams,
        true
      );
    }

    if (this.globalService.getCurrentRoute()?.startsWith('/search')) {
      this.clearProfileCachedPages();
      let searchParam = localStorage.getItem('search.filter');

      this.apiService?.getAds(
        {
          page: this.globalService.getSearchCurrentPage() - 1,
          search: searchParam,
        } as AdSearchParams,
        false
      );
    }
  }

  getProfileCachedAdCount() {
    return this.profile_cache.ad_count;
  }

  setProfileCachedAdCount(value: number | undefined) {
    this.profile_cache.ad_count = value;
  }

  getProfileCachedPages() {
    return this.profile_cache.pages;
  }

  getProfileVisibleAds() {
    return this.ad_visible_count;
  }

  setProfileVisibleAds(value: number) {
    this.ad_visible_count = value;
  }

  getProfileTotalAds() {
    return this.ad_total_count;
  }

  setProfileTotalAds(value: number) {
    this.ad_total_count = value;
  }

  clearProfileCachedPages() {
    this.profile_cache.pages = [];
    this.profile_cache.ad_count = undefined;
  }

  savePageInProfileCache(page: number, ads: Ad[]) {
    if (!this.profile_cache.pages) {
      this.profile_cache.pages = [];
    }
    this.profile_cache.pages.push({ page: page, ads: ads });
  }

  getSearchCachedAdCount() {
    return this.search_cache.ad_count;
  }

  setSearchCachedAdCount(value: number | undefined) {
    this.search_cache.ad_count = value;
  }

  clearSearchCachedPages() {
    this.search_cache.ad_count = undefined;
  }
}
