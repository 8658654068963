import { Component, Input, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { Ad, AdSearchParams } from 'src/app/common/interfaces';
import { UserMenuComponent } from '../user-menu/user-menu.component';
import { Location } from '@angular/common';
import { SubscriptionLike } from 'rxjs';
import { ModalService } from 'src/app/common/modal.service';
import { GlobalService } from 'src/app/common/global.service';
import { ApiService } from 'src/app/common/api.service';
import { CacheService } from 'src/app/common/cache.service';

@Component({
  selector: 'app-ad-detail',
  templateUrl: './ad-detail.component.html',
  styleUrls: ['./ad-detail.component.scss'],
})
export class AdDetailComponent implements OnInit {
  ad: Ad = {
    has_warranty: 0,
    is_mounted: 0,
    is_used: 1,
    images: [] as { photo_id: number; photo_path: string }[],
  } as Ad;
  editable: boolean = false;
  page: number = 0;
  prev: boolean = false;
  loaded: boolean = false;
  eventsSubscription: SubscriptionLike | undefined;
  paramsSubscription: SubscriptionLike | undefined;
  userSubscription: SubscriptionLike | undefined;

  params: string | undefined;

  constructor(
    private modalService: ModalService,
    private globalService: GlobalService,
    private apiService: ApiService,
    private cacheService: CacheService,
    private _route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    this.paramsSubscription = _route.params.subscribe((params) => {
      if (params) {
        this.getAdDetail(params['id']);
        this.params = params['id'];
      }
    });
  }

  showSideBar() {
    if (this.editable) {
      this.modalService.setSidebarContent(UserMenuComponent);
    }
  }

  ngOnInit(): void {
    this.showSideBar();
    if (
      this.ad.subcategory_id &&
      this.ad.category_id &&
      this.ad.title &&
      this.ad.price &&
      (!this.ad.images.length || this.ad.images.length === 0) &&
      this.editable
    ) {
      this.page = 1;
    }
  }

  async getAdDetail(id: string) {
    setTimeout(
      () =>
        id === 'create' && !this.globalService.getUserValue()
          ? this.globalService.setRoute('/')
          : undefined,
      1500
    );

    //buscamos el ad entre nuestros ads
    let downloadedAd;
    if (!this.globalService.getPreviousRoute()?.startsWith('/search')) {
      downloadedAd = this.globalService
        .getAdsList()
        ?.find((ad) => ad.id === Number(id));
    }

    if (downloadedAd) {
      this.ad = downloadedAd;
    } else if (id !== 'create') {
      await this.getAd(id);
    }
    if (!this.globalService.getUserValue()) {
      this.userSubscription = this.globalService
        .getUserAsObservable()
        .subscribe((data) => {
          this.checkEditable(id);
        });
    } else {
      this.checkEditable(id);
    }
    this.loaded = true;
  }

  checkEditable(id: string) {
    if (
      this.ad.user_id === this.globalService.getUserValue()?.id ||
      id === 'create'
    ) {
      this.editable = true;
      this.showSideBar();
    }
  }

  async getAd(id: string) {
    const response = await this.apiService.getAd(id);
    if (response.status === 200) {
      this.ad = response.message.ads;
    }
  }

  ngOnDestroy(): void {
    this.modalService.setSidebarContent(undefined);
    this.eventsSubscription?.unsubscribe();
    this.paramsSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
  }

  async subirAnuncio() {
    if (this.editable) {
      if (this.page === 2) {
        this.back(true);
      }
      if (this.page === 1) {
        this.page++;
      }
      if (this.page === 0) {
        if (this.ad.id) {
          if (this.ad.price.toString().includes(',')) {
            this.ad.price = Number(this.ad.price.toString().replace(',', '.'));
          }
          let result = await this.apiService.modifyAd(this.ad);
          if (result.status === 200) {
            this.page++;
          }
        } else {
          let result = await this.apiService.createAd(this.ad);
          if (result.status === 200 || result.status === 201) {
            let search = localStorage.getItem('profile.filter');
            let page = localStorage.getItem('profile.page');
            this.cacheService.clearProfileCachedPages();
            let total_ads = this.cacheService.getProfileTotalAds();
            if (total_ads) {
              this.cacheService.setProfileTotalAds(total_ads + 1);
            }

            this.apiService.getAds(
              {
                count: true,
                search: search,
                page: Number(page) - 1,
              } as AdSearchParams,
              true
            );

            this.ad.id = result.message.ad_id;
            this.page++;
          }
        }
      }
    }
  }
  goToPage(page: number) {
    return this.page > page || page === 0
      ? true
      : page === 1 && !this.checkDisabled(0)
      ? true
      : page === 2 && !this.checkDisabled(0) && !this.checkDisabled(1)
      ? true
      : false;
  }

  checkDisabled(page?: number) {
    switch (page ? page : this.page) {
      case 0:
        return !(
          this.ad.title !== undefined &&
          this.ad.title !== '' &&
          this.ad.subcategory_id !== undefined &&
          this.ad.price !== undefined &&
          this.ad.price !== null
        );
      case 1:
        return !(this.ad.images && this.ad.images.length > 0);
      default:
        return false;
    }
  }

  back(forced?: boolean) {
    if (this.page > 0 && !forced) {
      this.page--;
    } else {
      if (this.globalService.getPreviousRoute()) {
        this.router.navigate([this.globalService.getPreviousRoute()]);
      } else {
        if (this.editable) {
          this.router.navigate(['/profile/ads/']);
        } else {
          this.router.navigate(['/ads/']);
        }
      }
    }
  }
}
