import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/common/modal.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
  gallery = this.modalService.getInputData().list;
  selected = this.modalService.getInputData().selected;
  title = this.modalService.getInputData().title;
  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  nextPic() {
    this.selected++;
    if (this.selected === this.gallery.length) {
      this.selected = 0;
    }
  }
  prevPic() {
    this.selected--;
    if (this.selected === -1) {
      this.selected = this.gallery.length - 1;
    }
  }
}
