import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Ad, AdSearchParams, Category, Modal, User } from './interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { identity } from 'lodash';
import { map } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modal: Modal = {} as Modal;
  private sidebarContent: any = null;

  constructor() {}

  setContent(
    template: any,
    modal_data?: {
      type?: string;
      body: string;
      title?: string;
      onSubmit?: () => void;
    },
    size?: 'l' | 'm' | 's',

    input_data?: any
  ) {
    this.modal.template = template;
    this.modal.size = size ? size : 'm';
    this.modal.modal_data = modal_data ? modal_data : null;
    this.modal.input_data = input_data ? input_data : null;
  }

  getTemplate() {
    return this.modal.template;
  }

  getInputData() {
    return this.modal.input_data;
  }

  getSize() {
    return this.modal.size;
  }

  hasTitle() {
    return this.modal.modal_data && this.modal.modal_data.title ? true : false;
  }

  hasSubmit() {
    return this.modal.modal_data && this.modal.modal_data.onSubmit
      ? true
      : false;
  }

  getBody() {
    return this.modal.modal_data?.body;
  }

  getType() {
    return this.modal.modal_data?.type;
  }
  getTitle() {
    return this.modal.modal_data?.title;
  }

  submit() {
    if (this.modal.modal_data && this.modal.modal_data.onSubmit) {
      this.modal.modal_data.onSubmit();
    }
  }

  setSidebarContent(content: any) {
    this.sidebarContent = content;
  }

  getSidebarContent() {
    return this.sidebarContent;
  }
}
