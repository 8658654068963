import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from './global.service';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  access: boolean = false;
  constructor(
    private globalService: GlobalService,
    private cookieService: CookieService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let canAccess = this.cookieService.getCookie('tk') ? true : false;
    if (canAccess) {
      setTimeout(() => this.checkUser(), 2500);
    }

    return canAccess;
  }
  checkUser() {
    if (!this.globalService.getUserValue()) {
      this.globalService.setRoute('/');
    }
  }
}
