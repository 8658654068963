import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/common/api.service';
import { ContactData } from 'src/app/common/interfaces';
import { ModalService } from 'src/app/common/modal.service';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent implements OnInit {
  user = this.modalService.getInputData();

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  getEmails() {
    return this.user.contact_data.filter(
      (d: ContactData) => d.name === 'email'
    );
  }
  getPhones() {
    return this.user.contact_data.filter(
      (d: ContactData) => d.name === 'phone'
    );
  }

  getSocialsData() {
    const redes = this.user.contact_data.filter(
      (s: ContactData) =>
        s.name === 'facebook' ||
        s.name === 'twitter' ||
        s.name === 'linkedin' ||
        s.name === 'social'
    );

    return redes;
  }
}
