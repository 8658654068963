import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionLike } from 'rxjs';
import { ApiService } from 'src/app/common/api.service';
import { CacheService } from 'src/app/common/cache.service';
import { GlobalService } from 'src/app/common/global.service';
import { AdSearchParams, Filter } from 'src/app/common/interfaces';

@Component({
  selector: 'app-ad-search',
  templateUrl: './ad-search.component.html',
  styleUrls: ['./ad-search.component.scss'],
})
export class AdSearchComponent implements OnInit {
  filters: AdSearchParams | undefined;
  ad_count: number | undefined;
  loaded: boolean = false;

  paramsSubscription: SubscriptionLike | undefined;

  constructor(
    private globalService: GlobalService,
    private cacheService: CacheService,
    private apiService: ApiService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    this.paramsSubscription = this.activeRoute.queryParams.subscribe(
      (queryParams) => {
        this.loaded = false;
        this.cacheService.clearSearchCachedPages();
        this.filters = {
          filters: {} as Filter,
          page: this.globalService.getSearchCurrentPage() - 1,
        } as AdSearchParams;

        if (queryParams.category) {
          localStorage.setItem('search.category', queryParams.category);
          let cat = queryParams.category.split('|');
          if (cat[0] !== '-1') {
            this.filters.filters.category_id = cat[0];
          }
          if (cat[1] !== '-1') {
            this.filters.filters.subcategory_id = cat[1];
          }
        } else {
          localStorage.removeItem('search.category');
          globalService.category = '-1|-1';
        }

        if (queryParams.text) {
          localStorage.setItem('search.text', queryParams.text);
          this.filters.filters.search = queryParams.text;
        } else {
          localStorage.removeItem('search.text');
          globalService.search = '';
        }

        if (queryParams.page) {
          localStorage.setItem('search.page', queryParams.page);
          this.globalService.setSearchCurrentPage(Number(queryParams.page));
        }

        // const filter = localStorage.getItem('search.filter');
        // if (filter) {
        //   const filterParsed = JSON.parse(filter) as AdSearchParams;
        //   this.filters = filterParsed;
        // }
        // const searchedText = localStorage.getItem('search.text');
        // if (searchedText) {
        //   this.filters.filters = { search: searchedText } as Filter;
        // }
        this.getAds();
      }
    );
  }
  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.paramsSubscription?.unsubscribe();
  }

  updateAds() {
    localStorage.setItem('search.filter', JSON.stringify(this.filters));
    this.getAds();
  }

  async getAds() {
    if (this.filters) {
      this.filters.count = false;
      await this.getPage(this.globalService.getSearchCurrentPage());
      this.filters.count = true;
      if (!this.cacheService.getSearchCachedAdCount()) {
        let count = await this.apiService.getAds(this.filters, false);
        if (count.status === 200) {
          this.cacheService.setSearchCachedAdCount(count.message.count);
          this.loaded = true;
          this.changeDetector.detectChanges();
        }
      }
    }
  }

  async getPage(page: number) {
    if (this.filters) {
      this.globalService.setSearchCurrentPage(page);
      localStorage.setItem(
        'search.page',
        this.globalService.getSearchCurrentPage().toString()
      );
      this.router.navigate(['search'], {
        queryParams: { page: page },
        queryParamsHandling: 'merge',
        skipLocationChange: true,
      });
      this.filters.page = page - 1;
      this.filters.count = false;

      await this.apiService.getAds(this.filters, false);
    }
  }
}
