import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from './common/login.guard';
import { AdDetailComponent } from './routes/ad-detail/ad-detail.component';
import { AdSearchComponent } from './routes/ad-search/ad-search.component';
import { CookiesComponent } from './routes/cookies/cookies.component';
import { HelpComponent } from './routes/help/help.component';
import { LandingComponent } from './routes/landing/landing.component';
import { MyAdsComponent } from './routes/my-ads/my-ads.component';
import { PrivacyComponent } from './routes/privacy/privacy.component';
import { ProfileComponent } from './routes/profile/profile.component';
import { SitemapComponent } from './routes/sitemap/sitemap.component';
import { TermsComponent } from './routes/terms/terms.component';
import { VerifyEmailComponent } from './routes/verify-email/verify-email.component';

const routes: Routes = [
  { path: '', component: LandingComponent },

  {
    path: 'profile',
    pathMatch: 'prefix',
    canActivate: [LoginGuard],
    children: [
      {
        path: '',
        redirectTo: '/profile/userdata',
        pathMatch: 'full',
      },
      { path: 'userdata', component: ProfileComponent },
      { path: 'ads', component: MyAdsComponent },
      { path: 'favorite', component: ProfileComponent },
    ],
  },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'help', component: HelpComponent },
  { path: 'cookies', component: CookiesComponent },
  { path: 'search', component: AdSearchComponent },
  { path: 'ad/:id', component: AdDetailComponent },
  { path: 'verify', component: VerifyEmailComponent },
  //{ path: 'sitemap', component: SitemapComponent },
  { path: '**', pathMatch: 'full', redirectTo: '' },
];



@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
