import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  Ad,
  AdSearchParams,
  Category,
  ContactData,
  ContactType,
  User,
} from './interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { identity } from 'lodash';
import { map } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private token: string = '';
  private ads: Ad[] | undefined = undefined;
  private previousRoute: string | undefined;
  private currentRoute: string | undefined;
  private profile_current_page: number = 1;
  private search_current_page: number = 1;
  private contact_data_type: ContactType[] | undefined;
  private aditional_contact_data: ContactData[] | undefined;
  category: string = '-1|-1';
  search: string = '';

  private userSubject: BehaviorSubject<User | undefined> = new BehaviorSubject<
    User | undefined
  >(undefined);
  private user: Observable<User | undefined> = this.userSubject.asObservable();

  constructor(private http: HttpClient, private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (!this.currentRoute?.startsWith('/ad/')) {
          this.previousRoute = this.currentRoute;
        }
        this.currentRoute = event.url;
      }
    });
  }

  getContactDataType() {
    return this.contact_data_type;
  }

  setContactDataType(data: ContactType[]) {
    this.contact_data_type = data;
  }

  getProfileCurrentPage() {
    return this.profile_current_page;
  }

  setProfileCurrentPage(page: number) {
    this.profile_current_page = page;
  }

  getSearchCurrentPage() {
    return this.search_current_page;
  }

  setSearchCurrentPage(page: number) {
    this.search_current_page = page;
  }

  getColours() {
    return [
      { class: 'bg-red-500', name: 'Rojo' },
      { class: 'bg-yellow-200', name: 'Amarillo' },
      { class: 'bg-blue-500', name: 'Azul' },
      { class: 'bg-yellow-500', name: 'Naranja' },
      { class: 'bg-purple-500', name: 'Morado' },
      { class: 'bg-green-500', name: 'Verde' },
      { class: 'bg-white', name: 'Blanco' },
      { class: 'bg-gray-400', name: 'Gris' },
      { class: 'bg-black', name: 'Negro' },
    ];
  }

  getPreviousRoute() {
    return this.previousRoute;
  }

  getCurrentRoute() {
    return this.currentRoute;
  }

  setRoute(value: string) {
    this.router.navigate([value]);
  }

  clearUserData() {
    this.userSubject.next(undefined);
  }

  getUserValue() {
    return this.userSubject.value;
  }
  getContactData() {
    return this.aditional_contact_data;
  }

  setContactData(data: ContactData[]) {
    this.aditional_contact_data = data;
  }

  addContactData(data: ContactData) {
    if (!this.aditional_contact_data) {
      this.aditional_contact_data = [data];
    } else {
      this.aditional_contact_data.push(data);
    }
  }

  removeContactData(id: number) {
    const contact_to_remove = this.aditional_contact_data?.find(
      (d) => d.id === id
    );
    if (contact_to_remove) {
      this.aditional_contact_data?.splice(
        this.aditional_contact_data.indexOf(contact_to_remove),
        1
      );
    }
  }

  setUserValue(value: any) {
    this.userSubject.next(value);
  }

  getUserAsObservable(): Observable<User | undefined> {
    return this.user;
  }

  setToken(token: string) {
    this.token = token;
  }
  getToken() {
    return this.token;
  }

  getAdsList() {
    return this.ads;
  }

  setAdsList(ads: Ad[]) {
    this.ads = ads;
  }

  checkUserChanges(user: User) {
    return _.isEqual(
      this.objectCleaner(this.getUserValue()),
      this.objectCleaner(user)
    );
  }

  objectCleaner<T>(object: T) {
    return JSON.parse(JSON.stringify(object), (key, value) => {
      if (value == null || value == '' || value == [] || value == {})
        return undefined;
      return value;
    }) as T;
  }

  AdSearchParamsToStrings(object: AdSearchParams) {
    let params = {
      filters: object.filters ? JSON.stringify(object.filters) : null,
      showHidden: object.showHidden ? String(object.showHidden) : null,
      limit: object.limit ? String(object.limit) : null,
      page: object.page ? String(object.page) : null,
      count: object.count ? String(object.count) : null,
      id: object.id ? String(object.id) : null,
      onlyVisible: object.onlyVisible ? String(object.onlyVisible) : null,
      search: object.search ? String(object.search) : null,
    };

    return JSON.parse(JSON.stringify(params), (key, value) => {
      if (value == null || value == '' || value == [] || value == {})
        return undefined;
      return value;
    });
  }

  getParamsQuery(params: any) {
    const parameters = Object.keys(params)
      .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');

    return parameters ? '?' + parameters : '';
  }

  difference<T>(object: T, base: T) {
    function changes(object: any, base: any) {
      return _.transform(object, function (result: any, value, key) {
        if (!_.isEqual(value, base[key])) {
          result[key] =
            _.isObject(value) && _.isObject(base[key])
              ? changes(value, base[key])
              : value;
        }
      });
    }
    return changes(object, base);
  }
}
