import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/common/api.service';
import { Ad } from 'src/app/common/interfaces';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  ads: Ad[] = [];
  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.getAds();
  }

  async getAds() {
    const response = await this.apiService.getRandomAds(5);
    if (response.status === 200) {
      this.ads = response.message.ads;
    }
  }
}
