import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-collapsable',
  templateUrl: './collapsable.component.html',
  styleUrls: ['./collapsable.component.scss'],
})
export class CollapsableComponent implements AfterViewInit, OnChanges {
  @Input('collapsed') collapsed: boolean = true;
  @Input() title: string = '';
  @Input('alert') alert: boolean = false;
  @Input('updateTrigger') updateTrigger: boolean = false;
  @ViewChild('collapsable', { static: false }) collapsable:
    | ElementRef
    | undefined;

  constructor() {}
  ngOnChanges(): void {
    this.setContentHeight();
  }

  ngAfterViewInit() {
    if (!this.collapsed) this.setContentHeight();
  }

  collapse() {
    if (!this.collapsable) return;

    this.collapsed = !this.collapsed;
    if (!this.collapsed) {
      this.setContentHeight();
    } else {
      this.collapsable.nativeElement.style.maxHeight = 0 + 'px';
    }
  }

  setContentHeight() {
    if (this.collapsable && !this.collapsed) {
      this.collapsable.nativeElement.style.maxHeight =
        this.collapsable.nativeElement.scrollHeight + 75 + 'px';
    }
  }
}
