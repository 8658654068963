import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertService } from 'src/app/common/alert.service';
import { ApiService } from 'src/app/common/api.service';
import { CacheService } from 'src/app/common/cache.service';
import { GlobalService } from 'src/app/common/global.service';
import { Ad, Alert } from 'src/app/common/interfaces';
import { ModalService } from 'src/app/common/modal.service';
import { ContactInfoComponent } from 'src/app/routes/contact-info/contact-info.component';
import { GalleryComponent } from 'src/app/routes/gallery/gallery.component';
import { ModalAlertTemplateComponent } from '../modal-alert-template/modal-alert-template.component';
import { ModalListTemplateComponent } from '../modal-list-template/modal-list-template.component';

@Component({
  selector: 'app-ad',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.scss'],
})
export class AdComponent implements OnInit {
  @Input('anuncio') anuncio: Ad | undefined;
  currentPic: number = 0;
  _detail: boolean = false;
  hasDetail: boolean = false;
  @ViewChild('detail') set detail(content: any) {
    this.hasDetail =
      content.nativeElement.scrollHeight > content.nativeElement.offsetHeight;
  }

  constructor(
    private apiService: ApiService,
    private modalService: ModalService,
    private cacheService: CacheService,
    private globalService: GlobalService
  ) {
    if (!this.anuncio) return;
  }

  ngOnInit(): void {}

  async deleteAd(event: any) {
    event.stopPropagation();
    this.modalService.setContent(ModalAlertTemplateComponent, {
      type: 'warn',
      title: `Borrando "${this.anuncio?.title}"`,
      body: ` <p>
      Si eliminas el anuncio borraremos de nuestras bases de datos toda la
      información referente a él, incluyendo las imágenes asociadas.
    </p>
    <p class="font-semibold text-red-600 pt-8">¡Esta acción no tiene vuelta atrás!</p>
    <p class="font-semibold text-2xl pt-8">¿Estás seguro?</p>`,
      onSubmit: () => this.confirmDelete(this.anuncio),
    });
  }

  async improveSubscription(event: any) {
    event.stopPropagation();
    this.modalService.setContent(ModalAlertTemplateComponent, {
      type: 'warn',
      title: `Borrando "${this.anuncio?.title}"`,
      body: ` <p>
      Si eliminas el anuncio borraremos de nuestras bases de datos toda la
      información referente a él, incluyendo las imágenes asociadas.
    </p>
    <p class="font-semibold text-red-600 pt-8">¡Esta acción no tiene vuelta atrás!</p>
    <p class="font-semibold text-2xl pt-8">¿Estás seguro?</p>`,
      onSubmit: () => this.confirmDelete(this.anuncio),
    });
  }

  // async sellAd() {
  //   this.modalService.setModalContent(AdSellComponent, {
  //     color: 'accent',
  //     text: `¿Has vendido "${this.anuncio?.title}"?`,
  //     method: () => this.confirmSell(this.anuncio),
  //   });
  // }
  getPrice() {
    return this.anuncio?.price.toLocaleString('es-ES');
  }

  async confirmDelete(anuncio: Ad | undefined) {
    if (anuncio) {
      let response = await this.apiService.deleteAd(anuncio.id);
      if (response.status === 200) {
        this.cacheService.refreshCacheFromCurrentPage(true);
        if (anuncio.visible) {
          let ads_visibles = this.cacheService.getProfileVisibleAds();
          if (ads_visibles) {
            this.cacheService.setProfileVisibleAds(ads_visibles - 1);
          }
        }
        let total_ads = this.cacheService.getProfileTotalAds();
        if (total_ads) {
          this.cacheService.setProfileTotalAds(total_ads - 1);
        }
      }
    }
  }

  async confirmSell(anuncio: Ad | undefined) {
    if (anuncio) {
      // let response = await this.data.deleteAdFromAPI(anuncio.id);
      // if (response.status === 'success') {
      //   this.data.refreshCacheFromCurrentPage(true);
      // }
    }
  }

  async changeVisible(event: any) {
    event.stopPropagation();
    if (this.anuncio) {
      if (this.anuncio.images.length > 0) {
        if (this.anuncio.visible === 1) {
          await this.apiService.modifyAdVisibility(this.anuncio.id, 0);
        } else {
          this.setVisible();
        }
      } else {
        if (this.anuncio.visible === 1) {
          await this.apiService.modifyAdVisibility(this.anuncio.id, 0);
        } else {
          this.modalService.setContent(ModalAlertTemplateComponent, {
            type: 'warn',
            title: '¡Ninguna imagen encontrada!',
            body: `<p>
            Parece que no tienes ninguna imagen en éste anuncio. Para poder publicarlo
            necesitas al menos una imagen, ¡De esta forma el anuncio será mucho más
            atractivo!
          </p>
          <p class="pt-8">Puedes añadir hasta 8 imágenes en el panel de edición de anuncio.</p><p class="font-semibold text-2xl pt-8">¿Quieres ir al panel de edición?</p>`,
            onSubmit: () =>
              this.globalService.setRoute('/ad/' + this.anuncio?.id),
          });
        }
      }
    }
  }

  nextPic() {
    this.currentPic++;
    if (this.anuncio && this.currentPic === this.anuncio.images.length) {
      this.currentPic = 0;
    }
  }
  prevPic() {
    this.currentPic--;
    if (this.anuncio && this.currentPic === -1) {
      this.currentPic = this.anuncio.images.length - 1;
    }
  }
  openGallery(event: any) {
    event.stopPropagation();
    if (this.anuncio?.images && this.anuncio.images.length > 0) {
      this.modalService.setContent(GalleryComponent, undefined, 'l', {
        selected: this.currentPic,
        list: this.anuncio?.images,
        title: this.anuncio?.title,
      });
    }
  }

  async contactData(event: any) {
    event.stopPropagation();
    const contact_data = await this.apiService.getContactData(
      this.anuncio?.user_id,
      this.anuncio?.id
    );
    if (contact_data.status === 200) {
      this.modalService.setContent(ContactInfoComponent, undefined, 'l', {
        id: this.anuncio?.user_id,
        name: this.anuncio?.personal_name,
        surname: this.anuncio?.personal_surname,
        company_name: this.anuncio?.company_name,
        photo_url: this.anuncio?.photo_url,
        contact_data: contact_data.message.data,
      });
    }
  }

  async setVisible() {
    let number_contacts = this.globalService.getContactData();

    if (!number_contacts) {
      const response = await this.apiService.getContactData();
      if (response.status === 200) number_contacts = response.message.data;
    }

    if (this.anuncio && number_contacts && number_contacts.length > 0) {
      const total_ads = this.cacheService.getProfileVisibleAds() || 0;
      const max_ads = this.globalService.getUserValue()?.max_visible_ads || 0;
      if (total_ads < max_ads) {
        await this.apiService.modifyAdVisibility(this.anuncio.id, 1);
      } else {
        this.modalService.setContent(ModalAlertTemplateComponent, {
          type: 'warn',
          title: '¡Has llegado a tu límite de anuncios visibles!',
          body: `  <p>
          Parece que has alcanzado el número máximo de anuncios visibles para tu rango.
          Si quieres tener mas anuncios visibles puedes mejorar tu cuenta desde 
          tu perfil.
        </p>
        <p class="font-semibold text-2xl pt-8">¿Quieres ir a tu perfil?</p>`,
          onSubmit: () => this.globalService.setRoute('/profile/userdata'),
        });
      }
    } else {
      this.modalService.setContent(ModalAlertTemplateComponent, {
        type: 'warn',
        title: '¡Ningún medio de contacto detectado!',
        body: `  <p>
        Parece que no tienes ningún método de contacto (Por tu privacidad y por
        seguridad no mostramos por defecto el correo registrado). Para mostrar un
        anuncio necesitamos saber como quieres que contacten contigo los futuros
        compradores.
      </p>
      <p class="pt-8">
        Recuerda que podrás incluir más medios de contacto a través de tu perfil.
      </p><p class="font-semibold text-2xl pt-8">¿Quieres ir a tu perfil?</p>`,
        onSubmit: () => this.globalService.setRoute('/profile/userdata'),
      });
    }
  }

  checkModelos() {
    let marcas: string[] = [];
    if (this.anuncio && this.anuncio.compatibilities) {
      marcas = JSON.parse(this.anuncio.compatibilities);
    }

    return marcas;
  }

  openModels() {
    this.modalService.setContent(ModalListTemplateComponent, undefined, 'm', {
      list: this.checkModelos(),
      title: this.anuncio?.title,
    });
  }
}
