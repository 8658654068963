import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/common/alert.service';
import { Alert } from 'src/app/common/interfaces';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnInit {
  constructor(private alertService: AlertService) {}

  ngOnInit(): void {}

  removeAlert(alert: Alert) {
    this.alertService.removeAlert(alert);
  }
}
