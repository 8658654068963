import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { GlobalService } from 'src/app/common/global.service';
import { Ad } from 'src/app/common/interfaces';
import { menu } from 'src/app/common/menu';

@Component({
  selector: 'app-ad-editable',
  templateUrl: './ad-editable.component.html',
  styleUrls: ['./ad-editable.component.scss'],
})
export class AdEditableComponent implements OnInit {
  @Input('ad') ad: Ad = { has_warranty: 0, is_mounted: 0, is_used: 1 } as Ad;
  new_model: string | undefined;
  @ViewChild('title') set title(content: any) {
    content.control.markAsTouched({ onlySelf: true });
    this.changeDetectorRef.detectChanges();
  }
  @ViewChild('price') set price(content: any) {
    content.control.markAsTouched({ onlySelf: true });
    this.changeDetectorRef.detectChanges();
  }
  @ViewChild('category', { read: NgModel }) set category(content: any) {
    content.control.markAsTouched({ onlySelf: true });
    this.changeDetectorRef.detectChanges();
  }
  colours = this.globalService.getColours();
  categories = menu;

  constructor(
    private globalService: GlobalService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  priceCheck(event: any) {
    const pattern = /^[0-9+\,\ ]/;
    if (!pattern.test(String.fromCharCode(event.charCode))) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  ngOnInit(): void {}

  getMarcas() {
    let marcas: string[] = [];
    if (this.ad.compatibilities) {
      marcas = JSON.parse(this.ad.compatibilities);
    }

    return marcas;
  }

  getSelectedCategory(category_id: string, subcategory_id: string) {
    return `${category_id}|${subcategory_id}`;
  }

  getCategory() {
    if (
      (this.ad.category_id === -1 && this.ad.subcategory_id === -1) ||
      (this.ad.category_id === undefined &&
        this.ad.subcategory_id === undefined)
    ) {
      return undefined;
    } else {
      return `${this.ad.category_id}|${this.ad.subcategory_id}`;
    }
  }

  setCategory(value: string) {
    let raw_category = value.split('|');
    this.ad.category_id = Number(raw_category[0]);
    this.ad.subcategory_id = Number(raw_category[1]);
  }

  anadirMarca() {
    let marcas: string[] = this.ad.compatibilities
      ? JSON.parse(this.ad.compatibilities)
      : [];
    if (this.new_model && marcas.indexOf(this.new_model.toLowerCase()) === -1) {
      marcas.push(this.new_model.toLowerCase());
      this.guardarMarcas(marcas);
    }
    this.new_model = undefined;
  }

  quitarMarca(marca: string) {
    let marcas: string[] = JSON.parse(this.ad.compatibilities ?? []);
    marcas.splice(marcas.indexOf(marca), 1);
    this.guardarMarcas(marcas);
  }

  guardarMarcas(marcas: string[]) {
    this.ad.compatibilities = JSON.stringify(marcas);
  }
}
