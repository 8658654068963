import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NavigationEnd, NavigationStart } from '@angular/router';
import { ModalService } from 'src/app/common/modal.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  hidden: boolean = false;
  endPoint: NavigationEnd | undefined;
  startPoint: NavigationStart | undefined;
  @ViewChild('sidebarContent', { read: ViewContainerRef, static: false })
  set container(container: ViewContainerRef) {
    if (!container) return;
    setTimeout(() => {
      container.clear();
      const componentFactory = this.resolver.resolveComponentFactory(
        this.modalService.getSidebarContent()
      );
      container.createComponent(componentFactory);
    });
  }

  constructor(
    private resolver: ComponentFactoryResolver,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {}

  toggleSidebar() {
    console.log(this.hidden);
    this.hidden = !this.hidden;
    let sidebar = document.getElementById('sidebar');
    if (this.hidden) {
      sidebar?.classList.remove('spring-l-r');
      sidebar?.classList.add('spring-r-l');
    } else {
      sidebar?.classList.remove('spring-r-l');
      sidebar?.classList.add('spring-l-r');
    }
  }

  getSidebarContent() {
    return this.modalService.getSidebarContent();
  }
}
