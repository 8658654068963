import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionLike } from 'rxjs';
import { ApiService } from 'src/app/common/api.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
  paramsSubscription: SubscriptionLike | undefined;
  success: boolean = false;

  constructor(
    private activeRoute: ActivatedRoute,
    private apiService: ApiService
  ) {
    this.paramsSubscription = this.activeRoute.queryParams.subscribe(
      (queryParams) => {
        if (queryParams.email && queryParams.code) {
          this.activate(queryParams.email, queryParams.code);
        }
      }
    );
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.paramsSubscription?.unsubscribe();
  }
  async activate(email: string, code: string) {
    const response = await this.apiService.activateEmail(email, code);
    console.log(response);
    if (response.status === 200) {
      this.success = true;
    } else {
      this.success = false;
    }
  }
}
