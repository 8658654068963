import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/common/api.service';
import { ModalService } from 'src/app/common/modal.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  tab: 'login' | 'register' = 'login';
  remindMe: boolean = false;
  user: { email: string; password: string; password_repeat: string } = {
    email: '',
    password: '',
    password_repeat: '',
  };
  error: string = '';
  constructor(
    private apiService: ApiService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {}

  switchTab() {
    this.tab = this.tab === 'login' ? 'register' : 'login';
  }

  async access() {
    if (this.user.email.length < 3 || this.user.password.length < 3) {
      this.error = 'Tu nombre de usuario o contraseña son demasiado cortos';
      return;
    }

    let response;

    if (this.tab === 'register') {
      if (this.user.password === this.user.password_repeat) {
        response = await this.apiService.register(
          this.user.email,
          this.user.password
        );
      } else {
        this.error = 'Las contraseñas no coinciden';
        return;
      }
    }

    if (this.tab === 'login') {
      response = await this.apiService.login(
        this.user.email,
        this.user.password,
        this.remindMe
      );
    }

    this.modalService.setContent(undefined);
  }
}
