export const menu = [
  {
    id: 1,
    name: 'Motor',
    subcategories: [
      { id: 1, name: 'Coche' },
      { id: 2, name: 'Todoterreno' },
      { id: 3, name: 'Furgoneta' },
      { id: 4, name: 'Remolque' },
      { id: 5, name: 'Moto' },
      { id: 6, name: 'Maquinaria agrícola' },
      { id: 7, name: 'Maquinaria de construcción' },
    ],
  },
  {
    id: 2,
    name: 'Recambios y accesorios',
    subcategories: [
      { id: 8, name: 'Mecánica' },
      { id: 9, name: 'Electricidad' },
      { id: 10, name: 'Carrocería' },
    ],
  },
];
