import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Ad, AdSearchParams, ContactType, User } from './interfaces';
import { CookieService } from './cookie.service';
import { GlobalService } from './global.service';
import { AlertService } from './alert.service';
import { method } from 'lodash';
import { $ } from 'protractor';
import { CacheService } from './cache.service';
import { loadStripe } from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private stripe: any;
  private PORT = '3030';
  private URL =
    'https://images.tusrecambios.com' + (this.PORT ? ':' + this.PORT : '');

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private globalService: GlobalService,
    private alertService: AlertService,
    private cacheService: CacheService
  ) {
    loadStripe(
      'pk_live_51IO2IfB2psyLoy25WxCfg3lEL2nBkIkJVv5c3Okj5eaKahIMHY0Yb1xUER71aJDJFBmz34TWgc36gRTrBZhTDsWR00VYlCVuJu'
    ).then((r) => (this.stripe = r));
    cacheService.setApiService(this);
    let cookie = cookieService.getCookie('tk');

    if (cookie) {
      this.globalService.setToken(cookie);
      this.getUserData();
    }

    this.getContactTypes();
  }

  async getContactTypes(): Promise<any> {
    const response = await fetch(
      this.URL + '/api/scrapping/auth/contactTypes',
      { method: 'GET' }
    );

    const result = await response.json();

    switch (response.status) {
      case 200:
        this.globalService.setContactDataType(result.data);
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }
    return { status: response.status, message: result };
  }

  async activateEmail(email: string, code: string): Promise<any> {
    const response = await fetch(
      this.URL +
      '/api/scrapping/auth/verifyEmail?email=' +
      email +
      '&code=' +
      code,
      { method: 'GET' }
    );

    const result = await response.json();

    switch (response.status) {
      case 200:
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }
    return { status: response.status, message: result };
  }

  async addContactData(data: any) {
    const response = await fetch(this.URL + '/api/scrapping/auth/contactData', {
      method: 'POST',
      headers: {
        Authorization: this.globalService.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    switch (response.status) {
      case 200:
        const content_category = this.globalService
          .getContactDataType()
          ?.find((t) => t.id === data.typeID)?.name;

        if (content_category) {
          this.globalService.addContactData({
            name: content_category,
            data: data.data,
            id: result.contact_id,
          });
        }
        this.alertService.addAlert({
          type: 'success',
          text: 'Dato de contacto añadido',
          error_code: 'CONTACT_DATA_ADDED',
          visible: true,
        });
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }
    return { status: response.status, message: result };
  }

  async createCheckoutSession(priceId: string) {
    const response = await fetch(
      this.URL + '/api/scrapping/stripe/createCheckoutSession',
      {
        method: 'POST',
        headers: {
          Authorization: this.globalService.getToken(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ priceId: priceId }),
      }
    );

    const result = await response.json();

    switch (response.status) {
      case 200:
        this.stripe.redirectToCheckout({
          sessionId: result.sessionId,
        });
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }
    return { status: response.status, message: result };
  }

  async sendToCustomerPortal() {
    const response = await fetch(
      this.URL + '/api/scrapping/stripe/customerPortal',
      {
        method: 'POST',
        headers: {
          Authorization: this.globalService.getToken(),
        },
      }
    );

    const result = await response.json();

    switch (response.status) {
      case 200:
        window.location.href = result.url;
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }
    return { status: response.status, message: result };
  }

  async removeContactData(id: number) {
    const response = await fetch(
      this.URL + '/api/scrapping/auth/contactData?id=' + id,
      {
        method: 'DELETE',
        headers: {
          Authorization: this.globalService.getToken(),
        },
      }
    );

    const result = await response.json();

    switch (response.status) {
      case 200:
        this.globalService.removeContactData(id);
        this.alertService.addAlert({
          type: 'success',
          text: 'Método de contacto borrado',
          error_code: 'CONTACT_DATA_DELETED',
          visible: true,
        });
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }
    return { status: response.status, message: result };
  }

  async getContactData(id?: number, adId?: number) {
    const response = await fetch(
      this.URL +
      '/api/scrapping/auth/contactData' +
      (id ? '?id=' + id : '') +
      (adId ? '&adId=' + adId : ''),
      {
        method: 'GET',
        headers: id
          ? undefined
          : {
            Authorization: this.globalService.getToken(),
          },
      }
    );

    const result = await response.json();

    switch (response.status) {
      case 200:
        if (!id) {
          this.globalService.setContactData(result.data);
        }
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }
    return { status: response.status, message: result };
  }

  async register(email: string, password: string): Promise<any> {
    const response = await fetch(this.URL + '/api/scrapping/auth/signup', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        //Enviamos los datos
        email: email,
        password: password,
      }),
    });

    const result = await response.json();

    switch (response.status) {
      case 200:
        this.login(email, password);
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }
    return { status: response.status, message: result };
  }

  async login(
    email: string,
    password: string,
    remindMe: boolean = false
  ): Promise<any> {
    const response = await fetch(this.URL + '/api/scrapping/auth/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        //Enviamos los datos
        email: email,
        password: password,
      }),
    });

    const result = await response.json();

    switch (response.status) {
      case 200:
        this.globalService.setToken(result.token);
        this.cookieService.setCookie('tk', result.token, remindMe);
        this.getUserData();
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }

    return { status: response.status, message: result };
  }

  async getUserData(): Promise<any> {
    const response = await fetch(this.URL + '/api/scrapping/auth/user', {
      method: 'GET',
      headers: { Authorization: this.globalService.getToken() },
    });

    const result = await response.json();

    switch (response.status) {
      case 200:
        if (result.data[0].email_verified_at) {
          await this.getContactData();
          await this.getAdStats();
        }
        this.globalService.setUserValue(result.data[0]);
        break;
      case 401:
        this.cookieService.deleteCookie('tk');
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }
    return { status: response.status, message: result };
  }

  async requestVerificationEmail(): Promise<any> {
    const response = await fetch(
      this.URL + '/api/scrapping/auth/requestVerificationEmail',
      {
        method: 'GET',
        headers: { Authorization: this.globalService.getToken() },
      }
    );

    const result = await response.json();

    switch (response.status) {
      case 200:
        this.alertService.addAlert({
          type: 'info',
          text:
            'Se ha enviado un nuevo mensaje de verificación a ' +
            this.globalService.getUserValue()?.email,
          error_code: 'VERIFICATION_EMAIL_SENT',
          visible: true,
        });
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }
    return { status: response.status, message: result };
  }

  async getAdStats() {
    if (!this.cacheService.getProfileVisibleAds()) {
      let count = await this.getAds({ count: true, onlyVisible: true }, true);
      if (count.status === 200) {
        this.cacheService.setProfileVisibleAds(count.message.count);
      }
    }
    if (!this.cacheService.getProfileTotalAds()) {
      let count = await this.getAds({ count: true, onlyVisible: false }, true);
      if (count.status === 200) {
        this.cacheService.setProfileTotalAds(count.message.count);
      }
    }
  }

  async setUserData(user: User): Promise<any> {
    const response = await fetch(this.URL + '/api/scrapping/auth/userData', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.globalService.getToken(),
      },
      body: JSON.stringify(
        this.globalService.difference(user, this.globalService.getUserValue())
      ),
    });

    const result = await response.json();

    switch (response.status) {
      case 200:
        this.globalService.setUserValue(user);
        this.alertService.addAlert({
          type: 'success',
          text: 'Se han actualizado los datos de perfil',
          error_code: 'PROFILE_UPDATED',
          visible: true,
        });
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }
    return { status: response.status, message: result };
  }

  async addImageToProfile(data: FormData): Promise<any> {
    const response = await fetch(this.URL + '/api/scrapping/auth/userPhoto', {
      method: 'POST',
      body: data,
      headers: {
        enctype: 'multipart/form-data',
        Authorization: this.globalService.getToken(),
      },
    });

    const result = await response.json();

    switch (response.status) {
      case 200:
        this.alertService.addAlert({
          type: 'info',
          text: 'Se ha actualizado la foto de perfil',
          error_code: 'PROFILE_IMAGE_UPDATED',
          visible: true,
        });
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }

    return { status: response.status, message: result };
  }

  async createAd(ad: Ad): Promise<any> {
    const response = await fetch(this.URL + '/api/scrapping/announcement', {
      method: 'POST',
      headers: {
        Authorization: this.globalService.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(ad),
    });

    const result = await response.json();

    switch (response.status) {
      case 200:
      case 201:
        this.alertService.addAlert({
          type: 'success',
          text: 'Se ha creado un nuevo anuncio con título' + ad.title,
          error_code: 'AD_CREATED',
          visible: true,
        });
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }

    return { status: response.status, message: result };
  }

  async modifyAd(ad: Ad): Promise<any> {
    const response = await fetch(this.URL + '/api/scrapping/announcement', {
      method: 'PUT',
      headers: {
        Authorization: this.globalService.getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(ad),
    });

    const result = await response.json();

    switch (response.status) {
      case 200:
        this.alertService.addAlert({
          type: 'info',
          text: `Anuncio ${ad.title} actualizado`,
          error_code: 'AD_UPDATED',
          visible: true,
        });
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }

    return { status: response.status, message: result };
  }

  async modifyAdVisibility(id: number, visibility: number): Promise<any> {
    let config = {
      visible: visibility,
    } as any;

    if (id >= 0) {
      config.id = id;
    } else {
      config.hideAll = true;
    }

    const response = await fetch(
      this.URL + '/api/scrapping/announcement_visible',
      {
        method: 'PUT',
        body: JSON.stringify(config),
        headers: {
          Authorization: this.globalService.getToken(),
          'Content-Type': 'application/json',
        },
      }
    );

    const result = await response.json();

    switch (response.status) {
      case 200:
        this.cacheService.refreshCacheFromCurrentPage();
        let visible_ads =
          id === -1
            ? 0
            : (this.cacheService.getProfileVisibleAds() || 0) +
            (visibility === 0 ? -1 : 1);
        this.cacheService.setProfileVisibleAds(visible_ads);
        this.alertService.addAlert({
          type: 'info',
          text:
            id === -1
              ? `Se han ocultado todos tus anuncios para el resto de usuarios`
              : `El anuncio ahora esta ${visibility === 0 ? 'oculto' : 'visible'
              } para el resto de usuarios`,
          error_code:
            id === -1 ? 'AD_VISIBILITY_HIDEALL' : 'AD_VISIBILITY_UPDATED',
          visible: true,
        });
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }

    return { status: response.status, message: result };
  }

  async deleteAd(id: number): Promise<any> {
    const response = await fetch(
      this.URL + '/api/scrapping/announcement?id=' + id,
      {
        method: 'DELETE',
        headers: {
          Authorization: this.globalService.getToken(),
        },
      }
    );

    const result = await response.json();

    switch (response.status) {
      case 200:
        this.alertService.addAlert({
          type: 'success',
          text: 'Se ha borrado el anuncio',
          error_code: 'AD_DELETED',
          visible: true,
        });
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }

    return { status: response.status, message: result };
  }

  async getAds(params: any, self: boolean = false): Promise<any> {
    const response = await fetch(
      this.URL +
      '/api/scrapping/announcement' +
      this.globalService.getParamsQuery(
        this.globalService.AdSearchParamsToStrings(params)
      ),
      {
        method: 'GET',
        headers: self
          ? {
            Authorization: this.globalService.getToken(),
          }
          : undefined,
      }
    );

    const result = await response.json();

    switch (response.status) {
      case 200:
        if (!params.count) {
          this.globalService.setAdsList(result.ads);
        }
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }

    return { status: response.status, message: result };
  }

  async getRandomAds(number: number): Promise<any> {
    const response = await fetch(
      this.URL +
      '/api/scrapping/announcement?randomSample=' +
      number +
      '&onlyVisible=true',
      {
        method: 'GET',
      }
    );

    const result = await response.json();

    switch (response.status) {
      case 200:
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }

    return { status: response.status, message: result };
  }

  async getAd(id: string): Promise<any> {
    const response = await fetch(
      this.URL + '/api/scrapping/announcement?id=' + id,
      { method: 'GET' }
    );

    const result = await response.json();

    switch (response.status) {
      case 200:
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }

    return { status: response.status, message: result };
  }

  async addImageToAd(data: FormData): Promise<any> {
    const response = await fetch(
      this.URL + '/api/scrapping/announcement_image',
      {
        method: 'POST',
        headers: {
          enctype: 'multipart/form-data',
          Authorization: this.globalService.getToken(),
        },
        body: data,
      }
    );

    const result = await response.json();

    switch (response.status) {
      case 200:
        this.alertService.addAlert({
          type: 'info',
          text: 'Se ha añadido una imagen al anuncio',
          error_code: 'AD_IMAGE_UPLOADED',
          visible: true,
        });
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }

    return { status: response.status, message: result };
  }

  async removeImageFromAd(id: string, photoId: File): Promise<any> {
    const response = await fetch(
      this.URL +
      '/api/scrapping/announcement_image?id=' +
      id +
      '&photoId=' +
      photoId,
      {
        method: 'DELETE',
        headers: {
          Authorization: this.globalService.getToken(),
        },
      }
    );

    const result = await response.json();

    switch (response.status) {
      case 200:
        this.alertService.addAlert({
          type: 'info',
          text: 'Se ha eliminado una imagen al anuncio',
          error_code: 'AD_IMAGE_DELETED',
          visible: true,
        });
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: result.errorCode,
          error_code: result.errorCode,
          visible: true,
        });
        break;
    }

    return { status: response.status, message: result };
  }

  logout() {
    this.cookieService.deleteCookie('tk');
    this.globalService.clearUserData();
    this.globalService.setRoute('/');
    this.cacheService.clearProfileCachedPages();
  }

  async getSitemap(): Promise<any> {
    const response = await fetch(
      this.URL +
      '/api/sitemap.xml',
      {
        method: 'GET',
      }
    );

    const result = await response.text();

    switch (response.status) {
      case 200:
        break;
      default:
        this.alertService.addAlert({
          type: 'error',
          text: response.status.toString(),
          error_code: response.status.toString(),
          visible: true,
        });
        break;
    }

    return { status: response.status, message: result };
  }
}
