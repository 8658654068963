import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  cookies = localStorage.getItem('cookies');
  constructor() {}

  ngOnInit(): void {}
  acceptCookies() {
    localStorage.setItem('cookies', 'true');
    this.cookies = 'true';
  }
}
