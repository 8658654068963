import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { ApiService } from 'src/app/common/api.service';
import { CacheService } from 'src/app/common/cache.service';
import { GlobalService } from 'src/app/common/global.service';
import { ContactType, User } from 'src/app/common/interfaces';
import { ModalService } from 'src/app/common/modal.service';
import { ModalAlertTemplateComponent } from 'src/app/components/modal-alert-template/modal-alert-template.component';
import { UserMenuComponent } from '../user-menu/user-menu.component';

interface SocialNetwork {
  social: number;
  name: string;
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  passwordChange: boolean = false;
  isCompany: boolean = false;
  user: User | undefined;
  actions: string[] = [];
  @ViewChild('fileuploader', { static: false }) fileuploader:
    | ElementRef
    | undefined;
  @ViewChild('contactData', { static: false }) contactData:
    | ElementRef
    | undefined;

  @ViewChild('personalData', { static: false }) personalData:
    | ElementRef
    | undefined;

  heightChanges: boolean = false;

  userSubscription: SubscriptionLike | undefined;

  mail_to_add: string | undefined;
  phone_to_add: string | undefined;
  social_to_add: SocialNetwork | undefined = {} as SocialNetwork;

  constructor(
    private globalService: GlobalService,
    private modalService: ModalService,
    private apiService: ApiService,
    private cacheService: CacheService,
    private chageDetectorRef: ChangeDetectorRef
  ) {
    if (!this.globalService.getUserValue()) {
      this.userSubscription = globalService
        .getUserAsObservable()
        .subscribe((data) => {
          this.init();
        });
    } else {
      this.init();
    }
  }

  saveImage(file: File) {
    const cvs = document.createElement('canvas');
    const context = cvs.getContext('2d');

    const fileURL = URL.createObjectURL(file);
    const img = new Image();

    var maxW = 256;
    var maxH = 256;

    cvs.width = maxW;
    cvs.height = maxH;

    img.src = fileURL;
    img.addEventListener(
      'load',
      () => {
        URL.revokeObjectURL(fileURL);
        var iw = img.width;
        var ih = img.height;
        var scale = Math.max(maxW / iw, maxH / ih);
        var iwScaled = iw * scale;
        var ihScaled = ih * scale;

        if (!context) return;
        context.fillStyle = 'white';
        context.fillRect(0, 0, maxW, maxH);
        context.drawImage(
          img,
          (maxW - iwScaled) / 2,
          (maxH - ihScaled) / 2,
          iwScaled,
          ihScaled
        );

        cvs.toBlob(
          (blob) => {
            // Code to add blob to database. Put your db code in here
            this.uploadFile(blob);
          },
          'image/jpeg',
          0.7
        );
      },
      { once: true }
    );
  }

  init() {
    this.user = Object.assign({}, this.globalService.getUserValue());
    this.isCompany =
      this.user.company_name !== null && this.user.company_name !== '';
    if (!this.globalService.getContactData()) {
      this.getContactData();
    }
  }

  ngOnInit(): void {
    this.modalService.setSidebarContent(UserMenuComponent);
  }
  ngOnDestroy(): void {
    this.modalService.setSidebarContent(undefined);
    this.userSubscription?.unsubscribe();
  }

  saveChanges() {
    if (this.user) {
      this.apiService.setUserData(this.user);
    }
  }
  async getContactData() {
    await this.apiService.getContactData();
    this.heightChanges = !this.heightChanges;
  }

  changePassword() {
    if (!this.passwordChange) {
      this.passwordChange = true;
    } else {
      //TODO: Cambiamos la contraseña
      this.passwordChange = false;
    }
  }
  switchCompanyName() {
    if (this.user) {
      this.isCompany = !this.isCompany;
      if (!this.isCompany) {
        this.user.company_name = null;
      }
    }
  }

  fileChangeEvent(upload: any) {
    if (this.user) {
      let file = upload.target.files[0];
      if (file.type.match('image.*')) {
        this.saveImage(file);
      }
      if (this.fileuploader) {
        this.fileuploader.nativeElement.value = '';
      }
    }
  }

  async uploadFile(file: any) {
    if (this.user) {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('id', this.user.id.toString());

      let response = await this.apiService.addImageToProfile(formData);

      if (response.status === 200) {
        this.user.photo_url = response.message.data;
        this.chageDetectorRef.detectChanges();
      }
    }
  }
  getSocials() {
    const redes = this.globalService
      .getContactDataType()
      ?.filter(
        (s) =>
          s.name === 'facebook' ||
          s.name === 'twitter' ||
          s.name === 'linkedin' ||
          s.name === 'social'
      );

    return redes;
  }

  async addEmail() {
    const email_type_id = this.globalService
      .getContactDataType()
      ?.find((s) => s.name === 'email')?.id;
    console.log(this.globalService.getContactDataType());
    if (email_type_id && this.mail_to_add) {
      const response = await this.apiService.addContactData({
        typeID: email_type_id,
        data: this.mail_to_add,
      });
      if (response.status === 200) {
        this.mail_to_add = '';
        this.heightChanges = !this.heightChanges;
      }
    }
  }

  async addPhone() {
    const phone_type_id = this.globalService
      .getContactDataType()
      ?.find((s) => s.name === 'phone')?.id;

    if (phone_type_id && this.phone_to_add) {
      const response = await this.apiService.addContactData({
        typeID: phone_type_id,
        data: this.phone_to_add,
      });
      if (response.status === 200) {
        this.phone_to_add = '';
        this.heightChanges = !this.heightChanges;
      }
    }
  }

  async addSocial() {
    if (this.social_to_add?.social && this.social_to_add.name) {
      const response = await this.apiService.addContactData({
        typeID: this.social_to_add.social,
        data: this.social_to_add.name,
      });
      if (response.status === 200) {
        this.social_to_add = {} as SocialNetwork;
        this.heightChanges = !this.heightChanges;
      }
    }
  }
  refreshData() {
    this.apiService.getUserData();
  }

  requestVerification() {
    this.apiService.requestVerificationEmail();
  }

  setSocial(id: number) {
    if (!this.social_to_add) {
      this.social_to_add = { social: id, name: '' };
    } else {
      this.social_to_add.social = id;
    }
  }

  removeContactData(id: number) {
    const contact_data = this.globalService.getContactData();
    if (
      (contact_data && contact_data.length > 1) ||
      this.cacheService.getProfileVisibleAds() === 0
    ) {
      this.apiService.removeContactData(id);
    } else {
      this.modalService.setContent(ModalAlertTemplateComponent, {
        type: 'warn',
        title: 'Tienes anuncios visibles',
        body: `  <p>
        Parece que tienes anuncios visibles, no podemos eliminar todos tus datos de
        contacto ya que los clientes no podrían comunicarse contigo.
      </p>
      <p class="pt-8">
        Si no quieres que nadie te contacte, puedes ocultar tus anuncios y luego borrar
        los metodos de contacto.
      </p><p class="font-semibold text-2xl pt-8">¿Ocultar todos anuncios y continuar?</p>`,
        onSubmit: () => this.hideAds(id),
      });
    }
  }

  async hideAds(id: number) {
    const response = await this.apiService.modifyAdVisibility(-1, 0);
    if (response.status === 200) {
      this.apiService.removeContactData(id);
    }
  }

  getEmails() {
    return this.globalService
      .getContactData()
      ?.filter((d) => d.name === 'email');
  }
  getPhones() {
    return this.globalService
      .getContactData()
      ?.filter((d) => d.name === 'phone');
  }

  getSocialsData() {
    const redes = this.globalService
      .getContactData()
      ?.filter(
        (s) =>
          s.name === 'facebook' ||
          s.name === 'twitter' ||
          s.name === 'linkedin' ||
          s.name === 'social'
      );

    return redes;
  }

  checkEmail() {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(this.mail_to_add).toLowerCase());
  }

  checkPhone() {
    return this.phone_to_add && this.phone_to_add.length >= 7;
  }

  checkSocial() {
    return (
      this.social_to_add && this.social_to_add.social && this.social_to_add.name
    );
  }

  deleteUser() {}
  setTier(priceId: string) {
    this.apiService.createCheckoutSession(priceId);
  }
}
