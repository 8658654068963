import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api.service';
import { GlobalService } from 'src/app/common/global.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    private globalService: GlobalService
  ) {}

  ngOnInit(): void {}

  logout() {
    this.apiService.logout();
  }

  getLocation() {
    return window.location.pathname.startsWith('/ad/');
  }
}
