import {
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ApiService } from 'src/app/common/api.service';
import { CacheService } from 'src/app/common/cache.service';
import { Ad } from 'src/app/common/interfaces';
import { ModalService } from 'src/app/common/modal.service';
import { ModalAlertTemplateComponent } from '../modal-alert-template/modal-alert-template.component';

@Component({
  selector: 'app-ad-upload-images',
  templateUrl: './ad-upload-images.component.html',
  styleUrls: ['./ad-upload-images.component.scss'],
})
export class AdUploadImagesComponent implements OnInit {
  @Input('ad') ad: Ad | undefined;
  @ViewChild('fileuploader', { static: false }) fileuploader:
    | ElementRef
    | undefined;
  dragging: boolean = false;
  fileName = '';
  uploading: boolean = false;

  constructor(
    private changeDetection: ChangeDetectorRef,
    private apiService: ApiService,
    private cacheService: CacheService,
    private modalService: ModalService,
    private zone: NgZone
  ) {}

  // reset counter and append file to gallery when file is dropped
  async dropHandler(e: any) {
    e.preventDefault();
    this.dragging = false;
    if (this.ad?.id) {
      if (e.dataTransfer.files.length + this.ad.images.length > 8) return;
      for (const file of e.dataTransfer.files) {
        if (file.type.match('image.*')) {
          console.log('saving image');
          this.saveImage(file);
        }
      }
    }
  }

  delete(image: any) {
    this.modalService.setContent(ModalAlertTemplateComponent, {
      type: 'warn',
      title: `Borrando imagen`,
      body: `<div class="flex px-10 mt-2">
        <img src="https://images.tusrecambios.com/images/${image.photo_path}" class="w-28 h-28" />
        <div class="pl-10 text-left">
          <p>
            Si eliminas la imagen la borraremos de nuestros servidores y dejara de
            ser visible para el resto de usuarios.
          </p>
          <p class="font-semibold text-red-600 mt-4">
            ¡Esta acción no tiene vuelta atrás!
          </p>
        </div>
      </div>
      <p class="font-semibold text-2xl mt-10">¿Estás seguro?</p>`,
      onSubmit: () => this.deleteImage(image),
    });
  }

  async deleteImage(image: any) {
    if (!this.ad) return;
    let response = await this.apiService.removeImageFromAd(
      this.ad.id.toString(),
      image.photo_id
    );
    if (response.status === 200) {
      this.ad.images.splice(this.ad.images.indexOf(image), 1);
      if (this.ad.images.length === 0 && this.ad.visible) {
        this.changeVisible();
      }
      this.changeDetection.detectChanges();
    }
  }

  async changeVisible() {
    if (this.ad) {
      let response = await this.apiService.modifyAdVisibility(
        this.ad.id,
        this.ad.visible ? 0 : 1
      );
      if (response.status === 200) {
        this.cacheService.refreshCacheFromCurrentPage();
      }
    }
  }

  saveImage(file: File) {
    this.uploading = true;
    this.changeDetection.detectChanges();
    const cvs = document.createElement('canvas');
    const context = cvs.getContext('2d');

    const fileURL = URL.createObjectURL(file);
    const img = new Image();

    var maxW = 512;
    var maxH = 512;

    cvs.width = maxW;
    cvs.height = maxH;

    img.src = fileURL;
    img.addEventListener(
      'load',
      () => {
        URL.revokeObjectURL(fileURL);
        var iw = img.width;
        var ih = img.height;
        var scale = Math.max(maxW / iw, maxH / ih);
        var iwScaled = iw * scale;
        var ihScaled = ih * scale;

        if (!context) return;
        context.fillStyle = 'white';
        context.fillRect(0, 0, maxW, maxH);
        context.drawImage(
          img,
          (maxW - iwScaled) / 2,
          (maxH - ihScaled) / 2,
          iwScaled,
          ihScaled
        );
        this.addWatermark(cvs, context);
      },
      { once: true }
    );
  }

  addWatermark(cvs: HTMLCanvasElement, context: any) {
    const img2 = new Image();
    img2.src = '/assets/images/base/watermark.svg';
    img2.addEventListener(
      'load',
      () => {
        var iw = img2.width;
        var ih = img2.height;
        var scale = Math.min((cvs.width / iw) * 0.25, (cvs.height / ih) * 0.25);
        var iwScaled = iw * scale;
        var ihScaled = ih * scale;

        if (!context) return;

        context.drawImage(
          img2,
          cvs.width - iwScaled - 15,
          cvs.height - ihScaled - 15,
          iwScaled,
          ihScaled
        );
        cvs.toBlob(
          (blob) => {
            // Code to add blob to database. Put your db code in here
            this.uploadFile(blob);
          },
          'image/jpeg',
          0.7
        );
      },
      { once: true }
    );
  }

  async uploadFile(file: any) {
    if (this.ad?.id) {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('id', this.ad.id.toString());

      let response = await this.apiService.addImageToAd(formData);
      if (response.status === 200) {
        this.zone.run(() => {
          this.ad?.images.push({
            photo_id: response.message.data.query_result,
            photo_path: response.message.data.photo_path,
          });
          this.uploading = false;
        });
        // this.changeDetection.detectChanges();
      }
    }
  }

  dragLeaveHandler(e: any) {
    console.log(e);
    if (e.pageX !== 0 && e.pageY !== 0) {
      this.dragging = false;
    }
  }

  dragOverHandler(e: any) {
    if (this.hasFiles(e)) {
      this.dragging = true;
      e.preventDefault();
    }
  }

  fileChangeEvent(uploads: any) {
    if (this.ad?.id) {
      if (uploads.target.files.length + this.ad.images.length > 8) return;
      for (const file of uploads.target.files) {
        if (file.type.match('image.*')) {
          this.saveImage(file);
        }
      }
      if (this.fileuploader) {
        this.fileuploader.nativeElement.value = '';
      }
    }
  }

  hasFiles = ({ dataTransfer: { types = [] as string[] } }) =>
    types.indexOf('Files') > -1;

  ngOnInit(): void {}
}
