import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  OnInit,
} from '@angular/core';
import { LoginComponent } from '../../routes/login/login.component';
import { Category } from 'src/app/common/interfaces';
import { menu } from 'src/app/common/menu';
import { ModalService } from 'src/app/common/modal.service';
import { GlobalService } from 'src/app/common/global.service';
import { Router } from '@angular/router';
import { CacheService } from 'src/app/common/cache.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  categories = menu;

  constructor(
    private modalService: ModalService,
    private globalService: GlobalService,
    private router: Router
  ) {
    const savedCategory = localStorage.getItem('search.category');
    if (savedCategory) {
      this.globalService.category = savedCategory;
    }
    const savedSearch = localStorage.getItem('search.text');
    if (savedSearch) {
      this.globalService.search = savedSearch;
    }
  }

  ngOnInit(): void {}

  openLogin(): void {
    this.modalService.setContent(LoginComponent);
  }
  clearFilters() {
    this.globalService.search = '';
    this.updateSearch();
    this.globalService.category = '-1|-1';
    this.setCategory();
  }

  findAds() {
    if (
      this.globalService.search === '' &&
      this.globalService.category === '-1|-1'
    ) {
      this.globalService.setRoute('');
    } else {
      let params: any = {};
      if (this.globalService.search && this.globalService.search !== '') {
        params.text = this.globalService.search;
      }
      if (
        this.globalService.category &&
        this.globalService.category !== '-1|-1'
      ) {
        params.category = this.globalService.category;
      }
      params.page = '1';

      this.router.navigate(['search'], {
        queryParams: params,
      });
    }
  }
  setCategory() {
    localStorage.setItem('search.category', this.globalService.category);
  }

  updateSearch() {
    localStorage.setItem('search.text', this.globalService.search);
  }
}
