import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ApiService } from './common/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(private apiService: ApiService, private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle("Tusrecambios");
    this.meta.updateTag({
      name: "title",
      content: "Tusrecambios"
    });
    this.meta.updateTag({
      name: "description",
      content: "Encuentra la pieza que buscas en nuestro sitio web"
    });
    this.meta.updateTag({
      name: "image",
      content: ""
    });
    this.meta.updateTag({
      name: "site",
      content: ""
    });
  }
}
