import { EventEmitter, Component, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/common/api.service';
import { CacheService } from 'src/app/common/cache.service';
import { GlobalService } from 'src/app/common/global.service';
import { Ad } from 'src/app/common/interfaces';

@Component({
  selector: 'app-ad-created',
  templateUrl: './ad-created.component.html',
  styleUrls: ['./ad-created.component.scss'],
})
export class AdCreatedComponent implements OnInit {
  @Input('ad') ad: Ad | undefined;
  @Output() previous: EventEmitter<any> = new EventEmitter();
  hidden: boolean = true;
  constructor(
    private globalService: GlobalService,
    private cacheService: CacheService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    if (this.ad) {
      this.hidden = this.ad.visible === 1 ? false : true;
      if (
        this.checkData() &&
        this.checkMaxVisibleAds() &&
        this.ad &&
        !this.ad.visible &&
        !this.checkContactData()
      ) {
        this.apiService.modifyAdVisibility(this.ad.id, 1);
        this.cacheService.clearProfileCachedPages();
        this.hidden = false;
      }
    }
  }

  checkContactData() {
    let number = this.globalService.getContactData();
    return !number || number.length === 0;
  }
  toProfile() {
    this.globalService.setRoute('/profile/userdata');
  }

  checkMaxVisibleAds() {
    const visible_ads =
      (this.cacheService.getProfileVisibleAds() || 0) - (this.hidden ? 0 : 1);
    const max_visible = this.globalService.getUserValue()?.max_visible_ads || 0;
    return visible_ads < max_visible;
  }
  checkData() {
    return (
      this.ad?.category_id &&
      this.ad?.subcategory_id &&
      this.ad?.title &&
      this.ad?.images &&
      this.ad?.images.length > 0
    );
  }

  switchVisibility() {
    if (this.ad) {
      this.apiService.modifyAdVisibility(this.ad.id, this.hidden ? 1 : 0);
      this.cacheService.clearProfileCachedPages();
      this.hidden = !this.hidden;
    }
  }
}
