import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { LandingComponent } from './routes/landing/landing.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { ModalComponent } from './components/modal/modal.component';
import { LoginComponent } from './routes/login/login.component';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileComponent } from './routes/profile/profile.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AppComponent } from './app.component';
import { UserMenuComponent } from './routes/user-menu/user-menu.component';
import { CollapsableComponent } from './components/collapsable/collapsable.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MyAdsComponent } from './routes/my-ads/my-ads.component';
import { AdDetailComponent } from './routes/ad-detail/ad-detail.component';
import { AdComponent } from './components/ad/ad.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AdEditableComponent } from './components/ad-editable/ad-editable.component';
import { AdNoEditableComponent } from './components/ad-no-editable/ad-no-editable.component';
import { AdSellComponent } from './routes/ad-sell/ad-sell.component';
import { AdUploadImagesComponent } from './components/ad-upload-images/ad-upload-images.component';
import { AdSearchComponent } from './routes/ad-search/ad-search.component';
import { GalleryComponent } from './routes/gallery/gallery.component';
import { AdCreatedComponent } from './components/ad-created/ad-created.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { ContactInfoComponent } from './routes/contact-info/contact-info.component';
import { ModalAlertTemplateComponent } from './components/modal-alert-template/modal-alert-template.component';
import { ModalListTemplateComponent } from './components/modal-list-template/modal-list-template.component';
import { VerifyEmailComponent } from './routes/verify-email/verify-email.component';
import { HelpComponent } from './routes/help/help.component';
import { PrivacyComponent } from './routes/privacy/privacy.component';
import { CookiesComponent } from './routes/cookies/cookies.component';
import { TermsComponent } from './routes/terms/terms.component';

@NgModule({
  declarations: [
    LandingComponent,
    NavbarComponent,
    FooterComponent,
    ModalComponent,
    LoginComponent,
    ProfileComponent,
    SidebarComponent,
    AppComponent,
    UserMenuComponent,
    CollapsableComponent,
    MyAdsComponent,
    AdDetailComponent,
    AdComponent,
    AdEditableComponent,
    AdNoEditableComponent,
    AdSellComponent,
    AdUploadImagesComponent,
    AdSearchComponent,
    GalleryComponent,
    AdCreatedComponent,
    AlertsComponent,
    ContactInfoComponent,
    ModalAlertTemplateComponent,
    ModalListTemplateComponent,
    VerifyEmailComponent,
    HelpComponent,
    PrivacyComponent,
    CookiesComponent,
    TermsComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    MatInputModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCardModule,
    MatSelectModule,
    MatProgressBarModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
