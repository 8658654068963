import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ApiService } from 'src/app/common/api.service';
import { Ad } from 'src/app/common/interfaces';
import { menu } from 'src/app/common/menu';
import { ModalService } from 'src/app/common/modal.service';
import { ContactInfoComponent } from 'src/app/routes/contact-info/contact-info.component';
import { GalleryComponent } from 'src/app/routes/gallery/gallery.component';


@Component({
  selector: 'app-ad-no-editable',
  templateUrl: './ad-no-editable.component.html',
  styleUrls: ['./ad-no-editable.component.scss'],
})
export class AdNoEditableComponent implements OnInit {
  @Input('ad') ad: Ad = {} as Ad;
  @Input('contact') contact?: boolean = true;

  @ViewChild('description', { static: false }) description:
    | ElementRef
    | undefined;
  selected_image: number = 0;

  constructor(
    private modalService: ModalService,
    private apiService: ApiService, private title: Title, private meta: Meta
  ) { }

  ngOnInit(): void {
    this.title.setTitle(this.ad.title);
    this.meta.updateTag({
      name: "title",
      content: this.ad.title
    });
    this.meta.updateTag({
      name: "description",
      content: this.ad.description
    });
    this.meta.updateTag({
      name: "image",
      content: this.ad.images[0].photo_path
    });
    this.meta.updateTag({
      name: "site",
      content: window.location.href
    });
  }

  ngOnDestroy(): void {
    this.title.setTitle("Tusrecambios");
    this.meta.updateTag({
      name: "title",
      content: "Tusrecambios"
    });
    this.meta.updateTag({
      name: "description",
      content: "Encuentra la pieza que buscas en nuestro sitio web"
    });
    this.meta.updateTag({
      name: "image",
      content: ""
    });
    this.meta.updateTag({
      name: "site",
      content: ""
    });
  }
  getCategory() {
    return menu.find((c) => c.id === this.ad.category_id)?.name;
  }
  getSubcategory() {
    return menu
      .find((c) => c.id === this.ad.category_id)
      ?.subcategories.find((s) => s.id === this.ad.subcategory_id)?.name;
  }
  getImages() {
    if (this.ad.images.length > 5) {
      return [
        this.getPreviousImage(),
        this.ad.images[this.selected_image],
        this.getNextImage(),
      ];
    } else {
      return this.ad.images;
    }
  }

  getPreviousImage() {
    return this.selected_image === 0
      ? this.ad.images[this.ad.images.length - 1]
      : this.ad.images[this.selected_image - 1];
  }

  getNextImage() {
    return this.selected_image === this.ad.images.length - 1
      ? this.ad.images[0]
      : this.ad.images[this.selected_image + 1];
  }

  openGallery() {
    this.modalService.setContent(GalleryComponent, undefined, 'l', {
      selected: this.selected_image,
      list: this.ad.images,
      title: this.ad.title,
    });
  }

  getMarcas() {
    let marcas: string[] = JSON.parse(this.ad.compatibilities ?? []);
    return marcas;
  }

  async contactar() {
    const contact_data = await this.apiService.getContactData(
      this.ad.user_id,
      this.ad.id
    );
    if (contact_data.status === 200) {
      this.modalService.setContent(ContactInfoComponent, undefined, 'l', {
        id: this.ad.user_id,
        name: this.ad.personal_name,
        surname: this.ad.personal_surname,
        company_name: this.ad.company_name,
        photo_url: this.ad.photo_url,
        contact_data: contact_data.message.data,
      });
    }
  }
}
