import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Ad, AdSearchParams, Alert, Category, Modal, User } from './interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { delay, identity } from 'lodash';
import { map } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private alerts: Alert[] = [];

  constructor() {}

  async addAlert(alert: Alert) {
    alert.visible = true;
    if (this.alerts.length >= 6) {
      this.alerts.splice(this.alerts.indexOf(alert), 1);
    }
    this.alerts.push(alert);
    await delay(() => this.removeAlert(alert), 6000);
  }

  async removeAlert(alert: Alert) {
    alert.visible = false;
    await delay(() => this.alerts.splice(this.alerts.indexOf(alert), 1), 500);
  }

  getAlerts() {
    return this.alerts;
  }
}
