import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ModalService } from 'src/app/common/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @ViewChild('modalContent', { read: ViewContainerRef, static: false })
  set container(container: ViewContainerRef) {
    if (!container) return;
    setTimeout(() => {
      container.clear();
      const componentFactory = this.resolver.resolveComponentFactory(
        this.modalService.getTemplate()
      );
      container.createComponent(componentFactory);
    });
  }

  constructor(
    private modalService: ModalService,
    private resolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.modalService.setContent(null);
  }

  submitModal() {
    this.modalService.submit();
    this.modalService.setContent(null);
  }

  getSize() {
    switch (this.modalService.getSize()) {
      case 'l':
        return 'sm:max-w-1000px sm:h-90vh';
      case 'm':
      default:
        return 'sm:max-w-750px sm:h-500px';
    }
  }
}
