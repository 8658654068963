import { Component, OnInit } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { ApiService } from 'src/app/common/api.service';
import { CacheService } from 'src/app/common/cache.service';
import { GlobalService } from 'src/app/common/global.service';
import { AdSearchParams, Alert } from 'src/app/common/interfaces';
import { ModalService } from 'src/app/common/modal.service';
import { ModalAlertTemplateComponent } from 'src/app/components/modal-alert-template/modal-alert-template.component';
import { UserMenuComponent } from '../user-menu/user-menu.component';

@Component({
  selector: 'app-my-ads',
  templateUrl: './my-ads.component.html',
  styleUrls: ['./my-ads.component.scss'],
})
export class MyAdsComponent implements OnInit {
  filters: AdSearchParams | undefined;
  loaded: boolean = false;
  userSubscription: SubscriptionLike | undefined;

  constructor(
    private globalService: GlobalService,
    private modalService: ModalService,
    private cacheService: CacheService,
    private apiService: ApiService
  ) {
    if (!this.globalService.getUserValue()) {
      this.userSubscription = globalService
        .getUserAsObservable()
        .subscribe(async (user) => {
          this.init();
        });
    } else {
      this.init();
    }
  }

  async init() {
    let page = localStorage.getItem('profile.page');
    if (page) {
      this.globalService.setProfileCurrentPage(Number(page));
    }
    if (this.globalService.getUserValue()) {
      if (!this.globalService.getUserValue()?.email_verified_at) {
        this.globalService.setRoute('/profile/userdata');
      }
      this.filters = {
        page: this.globalService.getProfileCurrentPage(),
        onlyVisible: false,
      } as AdSearchParams;
      let filter = localStorage.getItem('profile.filter');
      if (filter) {
        this.filters.search = filter;
      }
      await this.getAds();
    }
  }
  ngOnInit(): void {
    this.modalService.setSidebarContent(UserMenuComponent);
  }
  ngOnDestroy(): void {
    this.modalService.setSidebarContent(undefined);
    this.userSubscription?.unsubscribe();
  }

  updateAds() {
    localStorage.setItem('profile.filter', this.filters?.search || '');
    this.globalService.setProfileCurrentPage(1);
    this.cacheService.clearProfileCachedPages();
    this.getAds();
  }

  async getAds() {
    if (this.filters) {
      this.filters.count = false;
      this.filters.onlyVisible = false;
      await this.getPage(this.globalService.getProfileCurrentPage());
      this.filters.count = true;
      if (!this.cacheService.getProfileCachedAdCount()) {
        let count = await this.apiService.getAds(this.filters, true);
        if (count.status === 200) {
          this.cacheService.setProfileCachedAdCount(count.message.count);
        }
      }
    }
  }

  createAd() {
    const total_ads = this.cacheService.getProfileTotalAds() || 0;
    const max_ads = this.globalService.getUserValue()?.max_ads || 0;
    if (total_ads >= max_ads) {
      this.modalService.setContent(ModalAlertTemplateComponent, {
        type: 'warn',
        title: '¡Has llegado a tu límite de anuncios!',
        body: `  <p>
          Parece que has alcanzado el número máximo de anuncios para tu rango.
          Si quieres crear nuevos anuncios puedes mejorar tu cuenta desde 
          tu perfil.
        </p>
        <p class="font-semibold text-2xl pt-8">¿Quieres ir a tu perfil?</p>`,
        onSubmit: () => this.globalService.setRoute('/profile/userdata'),
      });
    } else {
      this.globalService.setRoute('/ad/create');
    }
  }

  async getPage(page: number) {
    if (this.filters) {
      this.globalService.setProfileCurrentPage(page);
      localStorage.setItem(
        'profile.page',
        this.globalService.getProfileCurrentPage().toString()
      );
      this.filters.page = page - 1;
      this.filters.count = false;
      this.filters.onlyVisible = false;
      let cached = this.cacheService
        .getProfileCachedPages()
        ?.find((c) => c.page === page);
      if (cached) {
        this.globalService.setAdsList(cached.ads);
      } else {
        let resp = await this.apiService.getAds(this.filters, true);
        if (resp.status === 200) {
          this.cacheService.savePageInProfileCache(page, resp.message.ads);
        }
      }
      this.loaded = true;
    }
  }
  clearFilters() {
    if (this.filters) {
      this.filters.search = '';
      this.updateAds();
    }
  }
}
